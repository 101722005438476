import { useState, useEffect, useCallback, useRef } from 'react'

export function useCountdown(secondsProp = 60) {
  const secondsPropRef = useRef(secondsProp)
  const [seconds, setSeconds] = useState(secondsPropRef.current)
  const [running, setRunning] = useState(false)
  const timerRef = useRef()
  const runCountdown = useCallback(() => {
    setRunning(true)
  }, [])
  const stopCountdown = useCallback(() => {
    setRunning(false)
  }, [])
  const resetCountdown = useCallback(() => {
    setRunning(false)
    setSeconds(secondsPropRef.current)
  }, [])

  useEffect(() => {
    const clearInterval = () => window.clearInterval(timerRef.current)
    if (running) {
      timerRef.current = window.setInterval(() => {
        setSeconds(currentSeconds => {
          // reset
          if (currentSeconds <= 0) {
            setRunning(false)
            clearInterval()
            return secondsPropRef.current
          }
          return currentSeconds - 1
        })
      }, 1000)
    }
    return () => {
      clearInterval()
    }
  }, [running])

  return {
    isCountdownRunning: running,
    /** @deprecated simply use seconds instead */
    formattedSeconds: `${seconds}s`,
    seconds,
    runCountdown,
    stopCountdown,
    resetCountdown,
  }
}
