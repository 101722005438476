import React, { useState } from 'react'
import { Form, Input, Select, message } from 'antd'
import { validatePhone } from '@raylink/common/utils/validate'
import { getCookie } from '@raylink/common/utils/cookie'
import { useCountdown } from '@raylink/common/utils/use-countdown'
import { API, Axios, utmDecodeUri } from '@raylink/common/utils'
import { Storage } from '@raylink/common/constants/storage'
import { Checkbox } from '@raylink/common/components'
import { FormWrap, SendBtn, SubmitBtn, DemandWrap } from './atom'
import SuccessModal from './successModal'

const sizeOptions = [
  {
    value: '10人以下',
    label: '10人以下',
  },
  {
    value: '11-49人',
    label: '11-49人',
  },
  {
    value: '50-99人',
    label: '50-99人',
  },
  {
    value: '100-499人',
    label: '100-499人',
  },
  {
    value: '500人以上',
    label: '500人以上',
  },
]

const BusinessType = 6 // 验证码对应的业务类型, 6 :raylink 官网咨询页面
const CodeType = 1 // 验证码发送类型1短信

const { TextArea } = Input

export default function ContactForm({ ...rest }) {
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm()
  const { seconds, isCountdownRunning, runCountdown } = useCountdown(60)
  const http = new Axios(API.auth.host)

  const [demandList, setDemandList] = useState([
    {
      key: 'work',
      value: '企业远程办公',
      checked: false,
    },
    {
      key: 'support',
      value: '远程技术支持',
      checked: false,
    },
    {
      key: 'it',
      value: '远程IT运维',
      checked: false,
    },
    {
      key: 'manage',
      value: '远程设备管理',
      checked: false,
    },
    {
      key: 'other',
      value: '其他',
      checked: false,
    },
  ])
  const [demandErr, setDemandErr] = useState('')

  const phone = Form.useWatch('phone', form)
  const code = Form.useWatch('code', form)

  const getVerficationCode = async () => {
    form
      .validateFields(['phone'])
      .then(values => {
        if (!values) {
          return
        }
        const req = {
          phone,
          type: CodeType,
          business: BusinessType,
        }
        http.post(API.contact.sendVerifyCode, req).then(res => {
          runCountdown()
          if (res?.result) {
            message.success('验证码发送成功！')
          } else {
            message.error(res?.label)
          }
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const verifyDemand = () => {
    const checkedList = demandList.filter(item => item.checked)
    if (checkedList.length === 0) {
      setDemandErr('请至少选择一个项目需求')
    }
  }

  const onFinish = values => {
    verifyDemand()
    if (demandErr) return
    const validateReq = {
      phone,
      code,
      business: BusinessType,
      type: CodeType,
    }
    http.post(API.contact.validateVerifyCode, validateReq).then(res => {
      if (res?.code === 200) {
        const sourceUrl = getCookie(Storage.comefrom)
        const checkedStr = demandList
          .filter(item => item.checked)
          .map(item => item.value)
          .join('/')
        const recodeReq = {
          name: values.name,
          phone: values.phone,
          company: values.company,
          position: values.position,
          useRegulations: values.useRegulations,
          demand: `${checkedStr}${values.demand ? `/${values.demand}` : ''} `,
          sourceLink: utmDecodeUri(sourceUrl),
        }
        http.post(API.contact.rayRecord, recodeReq).then(res => {
          if (res?.code === 200) {
            setOpen(true)
            form.resetFields()
            const tempList = demandList.map(item => ({
              ...item,
              checked: false,
            }))
            setDemandList(tempList)
            // 百度广告埋点
            // eslint-disable-next-line no-underscore-dangle
            window._agl && window._agl.push(['track', ['success', { t: 3 }]])
          } else {
            message.error(res?.label)
          }
        })
      } else {
        message.error(res?.label)
      }
    })
  }

  return (
    <FormWrap
      form={form}
      name="contactForm"
      onFinish={onFinish}
      onFinishFailed={verifyDemand}
      {...rest}
    >
      <Form.Item name="company" label="企业名称" rules={[{ required: true }]}>
        <Input placeholder="请输入企业名称" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="联系电话"
        rules={[{ required: true }, { validator: validatePhone }]}
      >
        <Input placeholder="请输入您的手机号码" />
      </Form.Item>
      <div className="code-row">
        <Form.Item
          label="验证码"
          name="code"
          className="code"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="请输入短信验证码"
            className="codeInput"
            maxLength={6}
          />
        </Form.Item>
        <SendBtn
          type="button"
          disabled={!phone || isCountdownRunning}
          onClick={() => getVerficationCode()}
        >
          {isCountdownRunning ? `${seconds}s` : '发送验证码'}
        </SendBtn>
      </div>
      <Form.Item name="name" label="姓名">
        <Input placeholder="请填写您的姓名" />
      </Form.Item>
      <Form.Item name="position" label="职位">
        <Input placeholder="请填写您的职位" />
      </Form.Item>
      <Form.Item name="useRegulations" label="公司规模">
        <Select
          placeholder="请选择公司规模"
          options={sizeOptions}
          getPopupContainer={el => el.parentElement}
        />
      </Form.Item>
      <DemandWrap>
        <div className="demand-title">
          项目需求<span>（可多选）</span>
        </div>
        <div className="demand-list-div">
          {demandList.map((item, index) => (
            <Checkbox
              key={item.key}
              id={item.key}
              checked={item.checked}
              label={item.value}
              onChange={value => {
                const tempList = [...demandList]
                tempList[index].checked = value
                setDemandList(tempList)
                if (value) setDemandErr('')
              }}
            />
          ))}
          {demandErr && <div className="err">{demandErr}</div>}
        </div>
        <Form.Item name="demand">
          <TextArea
            placeholder="（选填）若您有更多需求，请在此处补充填写"
            autoSize={{ minRows: 3, maxRows: 6 }}
            className="demand-text-area"
          />
        </Form.Item>
      </DemandWrap>
      <Form.Item>
        <SubmitBtn type="submit" data-agl-cvt="5">
          预约咨询
        </SubmitBtn>
      </Form.Item>
      <SuccessModal open={open} setOpen={setOpen} />
    </FormWrap>
  )
}
