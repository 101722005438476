import styled from 'styled-components'
import Bg from '../../images/contact/bg.png'
import IpadBg from '../../images/contact/ipadBg.png'
import MobileBg from '../../images/contact/mobileBg.png'
import { Warpper } from '@raylink/common/components'
import { Media } from '@raylink/common/theme'

export const ContactWrap = styled.div`
  width: 100%;
  height: 1055px;
  background: url(${Bg}) no-repeat;
  ${Media.desktopContact`
    background-size: 100% auto;
  `}
  ${Media.ipad`
    height: auto;
    padding-bottom: 80px;
    background: url(${IpadBg}) center top no-repeat;
  `}
  ${Media.phone`
    height: auto;
    padding-bottom: 0;
    background: url(${MobileBg}) center top no-repeat;
    background-size: 7.5rem 5.16rem;
  `}
`

export const ContactContent = styled(Warpper)`
  display: flex;
  justify-content: flex-end;
  padding-top: 136px;
  ${Media.ipad`
    padding-top: 230px;
    justify-content: center;
  `}
  ${Media.phone`
    padding-top: 5.88rem;
  `}
`
export const FormWrap = styled.div`
  width: 534px;
  padding: 40px 56px 36px;
  border-radius: 12px;
  background: #fff;
  .title-div {
    margin-bottom: 32px;
  }
  .title {
    line-height: 48px;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    color: #212121;
  }
  .contact-desc {
    font-size: 16px;
    color: #878787;
    margin-top: 6px;
  }
  .contact-form {
    position: relative;
    top: unset;
    right: unset;
  }
  ${Media.ipad`
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0 16px 30px 0 rgba(204, 204, 204, 0.20);
  `}
  ${Media.phone`
    width: 100%;
    padding: 0 0.52rem 0.36rem;
    border-radius: 0;
    .title {
      line-height: 0.72rem;
      margin-bottom: 0.64rem;
      font-size: 0.48rem;
    }
    .contact-form {
      padding: 0;
    }
  `}
`
