import React from 'react'
import SuccessCheckImg from '../../../images/contact/success-check.png'
import { ModalMask, ModalContent, KnowBtn } from './atom'

export default function SuccessModal({ open, setOpen }) {
  return (
    open && (
      <ModalMask>
        <ModalContent>
          <img
            src={SuccessCheckImg}
            alt=""
            className="check-img"
            loading="lazy"
          />
          <div className="success-title">提交成功</div>
          <div className="success-text-div">
            <div className="success-text">感谢您选择RayLink私有化服务！</div>
            <div className="success-text">
              我们将在1个工作日内与您取得联系，请保持电话畅通。
            </div>
          </div>
          <KnowBtn onClick={() => setOpen(false)}>确定</KnowBtn>
        </ModalContent>
      </ModalMask>
    )
  )
}
