import styled from 'styled-components'
import { Media } from '@raylink/common/theme'

export const ModalMask = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.45);
`

export const ModalContent = styled.div`
  width: 424px;
  padding: 50px 40px 40px;
  border-radius: 12px;
  background: linear-gradient(180deg, #dffff0 0%, #ffffff 35.42%);
  text-align: center;
  .check-img {
    width: 142px;
  }
  .success-title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
    color: #212121;
  }
  .success-text-div {
    margin: 6px 0 24px;
  }
  .success-text {
    font-size: 14px;
    color: #595959;
    line-height: 1.5;
  }
  ${Media.phone`
    width: 6.86rem;
    padding: 0.8rem 0.6rem;
    border-radius: 0.24rem;
    .check-img {
      width: 2.84rem;
    }
    .success-title {
      font-size: 0.48rem;
    }
    .success-text-div {
      margin: 0.12rem 0 0.64rem;
    }
    .success-text {
      font-size: 0.28rem;
    }
  `}
`

export const KnowBtn = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  margin: auto;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  color: #fff;
  background: #11c871;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgb(17, 200, 113);
  }
  ${Media.phone`
    width: 100%;
    padding: 0.16rem 0;
    font-size: 0.28rem;
    border-radius: 0.08rem;
  `}
`
