import styled from 'styled-components'
import { Form } from 'antd'
import { Media, fillBtnStyle, lineBtnStyle } from '@raylink/common/theme'

export const FormWrap = styled(Form)`
  position: absolute;
  top: 141px;
  right: 96px;
  z-index: 10;
  .code-row {
    display: flex;
    justify-content: space-between;
    width: 422px;
    font-size: 14px;
  }
  .code {
    .codeInput {
      width: 214px;
      border-radius: 4px;
    }
    .codeBtn {
      width: 108px;
      height: 40px;
      color: #11c871;
      border-radius: 4px;
      border: 1px solid #eee;
      &:disabled {
        color: #b4b4b4;
        border-radius: 4px;
        border: 1px solid var(--line, #eee);
        background: #f4f7f9;
      }
    }
  }
  .ant-form-item .ant-form-item-label > label {
    justify-content: center;
  }
  ${Media.ipad`
    top: 116px;
    right: 72px;
  `}
  ${Media.phone`
    position: static;
    padding: 0.1rem 0.52rem 0.96rem;
    width: 100%;
    background: #fff;
    .ant-form-item .ant-form-item-label > label {
      justify-content: flex-start;
    }
    .code-row {
      position: relative;
      width: 6.46rem;
      font-size: 0.28rem;
    }
    .code {
      width: 4.3rem;
      .codeInput {
        width: 4.08rem;
        border-radius: 0.08rem;
      }
    }
  `}
`

export const SendBtn = styled.button`
  ${lineBtnStyle}
  width: 108px;
  height: 40px;
  color: #11c871;
  border-radius: 4px;
  border: 1px solid #eee;
  &:disabled {
    cursor: not-allowed;
    color: #b4b4b4;
    border: 1px solid #eee;
    background: #f4f7f9;
  }
  ${Media.phone`
    position: absolute;
    right: 0;
    bottom: 0.4rem;
    width: 2.16rem;
    height: 0.8rem;
    border-radius: 0.08rem;
    font-size: 0.28rem;
    &:disabled {
      border-radius: 0.08rem;
    }
  `}
`

export const SubmitBtn = styled.button`
  ${fillBtnStyle}
  width: 422px;
  height: 40px;
  margin-top: 20px;
  color: #fff;
  background: #11c871;
  &:disabled {
    cursor: not-allowed;
    background: linear-gradient(
        0deg,
        rgba(238, 238, 238, 0.9) 0%,
        rgba(238, 238, 238, 0.9) 100%
      ),
      #11c871;
  }
  ${Media.phone`
    width: 6.46rem;
    height: 0.8rem;
    margin-top: 0.08rem;
    font-size: 0.28rem;
  `}
`

export const DemandWrap = styled.div`
  max-width: 424px;
  .demand-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #212121;
    span {
      color: #878787;
    }
    &::before {
      content: '*';
      display: inline-block;
      font-family: SimSun, sans-serif;
      font-size: 14px;
      line-height: 1;
      margin-inline-end: 4px;
      color: #f2524f;
    }
  }
  .demand-list-div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 14px 40px;
    padding: 14px 0 22px;
    .err {
      position: absolute;
      left: 0;
      bottom: 2px;
      color: rgb(245, 74, 69);
      font-size: 12px;
    }
  }
  .demand-text-area {
    width: 100%;
  }
  ${Media.phone`
    max-width: 100%;
    .demand-title {
      font-size: 0.28rem;
    }
    .demand-list-div {
      gap: 0.22rem 0.8rem;
      padding: 0.24rem 0 0.44rem;
      .err {
        font-size: 0.24rem;
        bottom: 0.04rem;
      }
    }
  `}
`
