import React from 'react'
import { Layout, SEO } from '@raylink/common/components'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { antdThemeGlobal } from '@raylink/common/theme'
import { ContactWrap, ContactContent, FormWrap } from './index.style'
import ContactForm from '../../components/contact-form'

export default function ContactPage() {
  return (
    <ConfigProvider theme={antdThemeGlobal} locale={zhCN}>
      <Layout>
        <SEO
          title="联系我们-Raylink远程控制软件"
          keywords="远程控制软件,远控软件,Raylink远程控制软件"
          description="Raylink远程控制软件，专注为视觉行业提供IaaS、PaaS及SaaS云服务，欢迎加入"
          ogUrl="contact.html/"
        />
        <ContactWrap>
          <ContactContent>
            <FormWrap>
              <div className="title-div">
                <div className="title">联系我们</div>
                <div className="contact-desc">
                  填写并提交表格，专业技术顾问将在1个工作日内联系您
                </div>
              </div>
              <ContactForm className="contact-form" />
            </FormWrap>
          </ContactContent>
        </ContactWrap>
      </Layout>
    </ConfigProvider>
  )
}
